<script>
import { decimalAdd } from '@/utils/filters';
const horizonalLinePlugin = {
  id: 'horizontalLine',
  afterDraw: chartInstance => {
    var yValue;
    var yScale = chartInstance.scales['y-axis-0'];
    var canvas = chartInstance.chart;
    var ctx = canvas.ctx;
    var index;
    var line;
    var style;

    let mapData = chartInstance.data.datasets.map(x => x.data);
    // console.log(mapData);
    let arrTemp1 = [0, 0, 0];
    mapData.forEach(el => {
      arrTemp1[0] = decimalAdd(arrTemp1[0], el[0]);
      arrTemp1[1] = decimalAdd(arrTemp1[1], el[1]);
      arrTemp1[2] = decimalAdd(arrTemp1[2], el[2]);
    });

    chartInstance.data.datasets.forEach((dataset, index1) => {
      let meta = chartInstance.getDatasetMeta(index1);
      // const sumData = dataset;
      if (!meta.hidden && index1 == chartInstance.data.datasets.length - 1) {
        meta.data.forEach((element, index2) => {
          index2 == 0
            ? (ctx.fillStyle = '#004a89')
            : index2 == 1
            ? (ctx.fillStyle = '#ffabab')
            : index2 == 2
            ? (ctx.fillStyle = '#aaa')
            : '';
          // Draw the text in black, with the specified font
          ctx.font = Chart.helpers.fontString(13, 'normal');
          // Just naively convert to string for now
          let dataString = arrTemp1[index2].toString();
          // Make sure alignment settings are correct
          ctx.textAlign = 'center';
          ctx.textBaseline = 'middle';
          let padding = 5;
          let position = element.tooltipPosition();
          ctx.fillText(dataString, position.x, position.y - 13 / 2 - padding);
        });
      }
    });

    if (chartInstance.options.horizontalLine) {
      for (
        index = 0;
        index < chartInstance.options.horizontalLine.length;
        index++
      ) {
        line = chartInstance.options.horizontalLine[index];

        if (!line.style) {
          style = '#080808';
        } else {
          style = line.style;
        }

        if (line.y) {
          yValue = yScale.getPixelForValue(line.y);
        } else {
          yValue = 0;
        }
        ctx.lineWidth = 3;

        if (yValue) {
          window.chart = chartInstance;
          ctx.beginPath();
          ctx.moveTo(0, yValue);
          ctx.lineTo(canvas.width, yValue);
          ctx.strokeStyle = style;
          ctx.stroke();
        }

        if (line.text) {
          ctx.fillStyle = style;
          ctx.fillText(line.text, 0, yValue + ctx.lineWidth);
        }
      }
      return;
    }
  },
};
import { Bar } from 'vue-chartjs';
import Chart from 'chart.js';

export default {
  extends: Bar,
  props: ['data', 'options'],
  beforeMount() {
    this.addPlugin(horizonalLinePlugin);
  },
  mounted() {
    this.renderChart(this.data, this.options);
  },
};
</script>
