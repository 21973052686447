<template>
  <div class="article" :class="{ active: fullScreen }">
    <div class="contents">
      <div class="status_table">
        <button
          class="btn_sub1 restart_btn"
          v-show="fullScreen"
          :disabled="!clear"
          @click="(clear2 = false), (clear = false)"
        >
          재시작
        </button>
        <button
          class="btn_sub1"
          @click="fullScreenFn"
          :disabled="filteredLots.length < 1"
        >
          {{ fullScreen ? '닫기' : '전체화면' }}
        </button>
        <div class="mes_tbl_wrap">
          <table class="mes_tbl">
            <colgroup>
              <col v-for="(n, index) in 8" :key="index" />
            </colgroup>
            <thead>
              <tr>
                <th rowspan="2">지시일자</th>
                <th rowspan="2">납품 요구일</th>
                <th colspan="3">제품 공정정보</th>
                <th colspan="2">공정 진행현황</th>
                <th rowspan="2">
                  생산완료율
                </th>
              </tr>
              <tr>
                <th>제품명</th>
                <th>LOT NO</th>
                <th>공정수</th>
                <th>지시수량</th>
                <th>생산수량</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(lot, index) in filteredLots"
                :key="lot.id + redraw"
                @click="selectRow(index), (clear = true)"
                :class="{ active: selectedIndex == index }"
                tabindex="0"
              >
                <td>{{ lot.input_date }}</td>
                <td>{{ lot.deadline_date }}</td>
                <td>{{ findInfoFromId(products, lot.product_id).name }}</td>
                <td>{{ `L${lot.lot_number}` }}</td>
                <td>
                  {{ findInfoFromId(boms, lot.bom_id).bom_process.length }}
                </td>
                <td>{{ $makeComma(lot.quantity) }}</td>
                <td>{{ $makeComma(getMinPassQuantity(lot.bom_info)) }}</td>
                <td
                  :class="{
                    active:
                      perNum(getMinPassQuantity(lot.bom_info), lot.quantity) >
                      80,
                    active2:
                      perNum(getMinPassQuantity(lot.bom_info), lot.quantity) <=
                        80 &&
                      perNum(getMinPassQuantity(lot.bom_info), lot.quantity) >
                        60,
                    active3:
                      perNum(getMinPassQuantity(lot.bom_info), lot.quantity) <=
                        60 &&
                      perNum(getMinPassQuantity(lot.bom_info), lot.quantity) >
                        40,
                    active4:
                      perNum(getMinPassQuantity(lot.bom_info), lot.quantity) <=
                        40 &&
                      perNum(getMinPassQuantity(lot.bom_info), lot.quantity) >=
                        0,
                  }"
                >
                  {{
                    `${perNum(getMinPassQuantity(lot.bom_info), lot.quantity)}%`
                  }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="progress_contents">
        <div class="swiper-container">
          <div class="swiper-wrapper">
            <process-status-card
              v-for="lot_process in target_lot2 != null
                ? target_lot2.bom_info.bom_process
                : []"
              :key="lot_process.id + redraw"
              :process_id="process.process_id"
              :process_name="
                findInfoFromId(process, lot_process.process_id).name
              "
              :sub="lot_process.index + 1"
              :id="`chartData${lot_process.id}`"
              :lot_quantity="target_lot2.quantity"
              :wait_quantity="getWaitQuantity(lot_process)"
              :production_info="lot_process.production_info"
              :lot_id="target_lot2.id"
              :bom_process_id="lot_process.id"
              :lot_type_id="3"
            ></process-status-card>
          </div>
          <!-- Add Arrows -->
        </div>
        <div class="slide_btn_wrap">
          <div>
            <button type="button" class="button-prev">
              <i class="fa fa-angle-left"></i>
            </button>
            <button type="button" class="button-next">
              <i class="fa fa-angle-right"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper, { Navigation, Pagination, Autoplay } from 'swiper';
import 'swiper/swiper-bundle.css';

import ProcessStatusCard from '@/layouts/components/ProcessStatusCard.vue';

import { mapGetters } from 'vuex';

import fetchMixin from '@/mixins/fetch';
import SpinnerMixin from '@/mixins/spinner';
import ModalMixin from '@/mixins/modal';

Swiper.use([Navigation, Pagination, Autoplay]);

export default {
  mixins: [fetchMixin, SpinnerMixin, ModalMixin],
  components: {
    ProcessStatusCard,
  },
  data() {
    return {
      fullScreen: false,
      clear: false,
      clear2: false,
      slideInterval: null,
      swiper: null,
      count: 0,
      countFocus: 0,
      redraw: 0,
      interval: null,
      timeInterval: null,
      timerCount: 60,
      timerMax: 60,
      screenWidth: this.$screen.width,
    };
  },
  computed: {
    ...mapGetters({
      boms: 'getBom',
      process: 'getProcess',
      materials: 'getMaterial',
      products: 'getProduct',
      companys: 'getCompany',
      lot_types: 'getLotType',
      target_lot: 'getTargetLotFromProcessStatus',
      unit_conversion: 'getUnitConversion',
      units: 'getUnitCodes',
      lots: 'getLotStatusListFromProcessStatus',
      keyCountForReDraw: 'getKeyCountForReDrawFromProcessStatus',
      selectedIndex: 'getSelectedIndexFromProcessStatus',
    }),
    filteredLots() {
      return this.lots
        .filter(
          x =>
            x.lot_type_id ==
              this.lot_types.find(x => x.detail == 'processing').id &&
            !this.findInfoFromId(this.products, x.product_id).oem_product_yn,
        )
        .sort((a, b) => new Date(b.input_date) - new Date(a.input_date));
    },
    target_lot2() {
      if (this.filteredLots != undefined && this.selectedIndex != -1) {
        let selectLotList = this.lodash.clonedeep(
          this.filteredLots[this.selectedIndex],
        );

        selectLotList.bom_info.bom_process = selectLotList.bom_info.bom_process.sort(
          (a, b) => a.index - b.index,
        );
        return selectLotList;
      }
      return null;
    },
  },
  methods: {
    fullScreenFn() {
      this.fullScreen = !this.fullScreen;
      this.selectRow(0);
      this.fullScreen && this.swiper.slides.length > 5
        ? this.swiper.autoplay.start()
        : this.swiper.autoplay.stop() && this.selectRow(-1);
    },
    perNum(bom_info, quantity) {
      if (bom_info != undefined && quantity != undefined) {
        return ((Number(bom_info) / Number(quantity)) * 100).toFixed(0);
      } else {
        return 0;
      }
    },
    selectRow(index) {
      this.$store.commit('setSelectedIndexToProcessStatus', index);
      this.$store.commit(
        'setTargetLotToProcessStatus',
        this.filteredLots[index],
      );
      this.redraw += 100;

      let mesTblTr = document.querySelectorAll('.mes_tbl tbody tr');
      mesTblTr[this.countFocus].focus();
    },
    getMinPassQuantity(bom_info) {
      console.log('bom_info', bom_info);

      if (bom_info != undefined) {
        console.log('bom_info', bom_info);

        let max_index = 0;
        bom_info.bom_process.forEach(el => {
          if (el.index > max_index) max_index = el.index;
        });

        console.log('max_index', max_index);

        let min_pass_quantity = 99999999999999;
        const filterLastIndexProcess = this.lodash.clonedeep(
          bom_info.bom_process.filter(x => x.index == max_index),
        );

        filterLastIndexProcess.forEach(el => {
          if (el.production_info == null) min_pass_quantity = 0;
          else if (el.production_info.pass_quantity < min_pass_quantity)
            min_pass_quantity = el.production_info.pass_quantity;
        });

        return min_pass_quantity;
      } else {
        return 0;
      }
    },
    getCvrRatio(input, output) {
      if (input == output) return 1;
      const hit = this.unit_conversion.find(
        x => x.in_unit_id == input && x.out_unit_id == output,
      );
      return hit != undefined ? hit.cvr_ratio : 1;
    },
    getWaitQuantity(tar) {
      const before_process = this.target_lot.bom_info.bom_process.filter(
        x => x.index == tar.index - 1,
      );

      if (before_process.length < 1) {
        return this.target_lot.quantity;
      } else {
        let minQuantity = 9999999999;
        before_process.forEach(element => {
          if (element.production_info == null) {
            minQuantity = 0;
          } else {
            if (element.production_info.pass_quantity < minQuantity) {
              minQuantity = element.production_info.pass_quantity;
            }
          }
        });
        return minQuantity;
      }
    },
    selectLot(arg) {
      this.FETCH_LOT_PRODUCTION(arg.id);
      this.hideLotSearch();
    },
    async FETCH_PRODUCTION_LIST() {
      await this.$store
        .dispatch('FETCH_PRODUCTION_LIST')
        .then(() => {
          this.redraw += 100;
        })
        .catch(error => {
          console.log(error);
          this.openOneButtonModal(
            '로드 중 오류',
            '공정 진행현황을 불러오는 중 오류 발생',
          );
        });
    },
  },
  mounted() {
    let slidesPerView = 6;
    if (this.$screen.width < 1280) {
      slidesPerView = 5;
    }
    this.swiper = new Swiper('.swiper-container', {
      slidesPerView,
      freeMode: true,
      resizeReInit: true,
      observer: true,
      observeParents: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      navigation: {
        nextEl: '.button-next',
        prevEl: '.button-prev',
      },
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
    });
    this.swiper.autoplay.stop();
  },
  destroyed() {
    clearInterval(this.interval);
    clearInterval(this.timeInterval);
    clearInterval(this.slideInterval);
  },
  async created() {
    if (this.boms.length < 1) {
      await this.FETCH('FETCH_BOM', 'BOM');
    }
    if (this.process.length < 1) {
      await this.FETCH('FETCH_PROCESS', '공정');
    }
    if (this.materials.length < 1) {
      await this.FETCH('FETCH_MATERIAL_WITH_COMPANY', '자재');
    }
    if (this.products.length < 1) {
      await this.FETCH('FETCH_PRODUCT_WITH_COMPANY', '제품');
    }
    if (this.companys.length < 1) {
      await this.FETCH('FETCH_COMPANY', '거래처');
    }
    if (this.lot_types.length < 1) {
      await this.FETCH('FETCH_LOT_TYPE', '공정상태');
    }
    if (this.units.length < 1) {
      await this.FETCH('FETCH_UNIT', '단위');
    }
    if (this.lots.length < 1) {
      await this.FETCH('FETCH_PRODUCTION_LIST', '공정현황');
    }
    if (this.unit_conversion.length < 1) {
      await this.FETCH('FETCH_UNIT_CONVERSION', '단위변환식');
    }

    if (this.filteredLots.length == 0) {
      this.$store.commit('setSelectedIndexToProcessStatus', -1);
    }

    this.timerCount = this.timerMax;
    this.$emit('changetimer', this.timerCount);

    this.interval = setInterval(() => {
      this.$emit('changetimer', this.timerMax);
      this.FETCH_PRODUCTION_LIST();
      this.timerCount = this.timerMax;
      clearInterval(this.timeInterval);
      this.timeInterval = setInterval(() => {
        this.timerCount -= 1;
        this.$emit('changetimer', this.timerCount);
      }, 1000);
    }, this.timerMax * 1000);

    this.timeInterval = setInterval(() => {
      this.timerCount -= 1;
      this.$emit('changetimer', this.timerCount);
    }, 1000);

    this.slideInterval = setInterval(() => {
      if (this.fullScreen && !this.clear2) {
        if (this.filteredLots.length - 1 == this.countFocus) {
          this.countFocus = 0;
        } else {
          this.countFocus++;
        }
        this.selectRow(this.countFocus);
        if (this.clear) {
          this.clear2 = true;
        }
      }
    }, 10000);
  },
};
</script>

<style lang="scss" scoped></style>
