var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"article",class:{ active: _vm.fullScreen }},[_c('div',{staticClass:"contents"},[_c('div',{staticClass:"status_table"},[_c('button',{directives:[{name:"show",rawName:"v-show",value:(_vm.fullScreen),expression:"fullScreen"}],staticClass:"btn_sub1 restart_btn",attrs:{"disabled":!_vm.clear},on:{"click":function($event){(_vm.clear2 = false), (_vm.clear = false)}}},[_vm._v(" 재시작 ")]),_c('button',{staticClass:"btn_sub1",attrs:{"disabled":_vm.filteredLots.length < 1},on:{"click":_vm.fullScreenFn}},[_vm._v(" "+_vm._s(_vm.fullScreen ? '닫기' : '전체화면')+" ")]),_c('div',{staticClass:"mes_tbl_wrap"},[_c('table',{staticClass:"mes_tbl"},[_c('colgroup',_vm._l((8),function(n,index){return _c('col',{key:index})}),0),_vm._m(0),_c('tbody',_vm._l((_vm.filteredLots),function(lot,index){return _c('tr',{key:lot.id + _vm.redraw,class:{ active: _vm.selectedIndex == index },attrs:{"tabindex":"0"},on:{"click":function($event){_vm.selectRow(index), (_vm.clear = true)}}},[_c('td',[_vm._v(_vm._s(lot.input_date))]),_c('td',[_vm._v(_vm._s(lot.deadline_date))]),_c('td',[_vm._v(_vm._s(_vm.findInfoFromId(_vm.products, lot.product_id).name))]),_c('td',[_vm._v(_vm._s(("L" + (lot.lot_number))))]),_c('td',[_vm._v(" "+_vm._s(_vm.findInfoFromId(_vm.boms, lot.bom_id).bom_process.length)+" ")]),_c('td',[_vm._v(_vm._s(_vm.$makeComma(lot.quantity)))]),_c('td',[_vm._v(_vm._s(_vm.$makeComma(_vm.getMinPassQuantity(lot.bom_info))))]),_c('td',{class:{
                  active:
                    _vm.perNum(_vm.getMinPassQuantity(lot.bom_info), lot.quantity) >
                    80,
                  active2:
                    _vm.perNum(_vm.getMinPassQuantity(lot.bom_info), lot.quantity) <=
                      80 &&
                    _vm.perNum(_vm.getMinPassQuantity(lot.bom_info), lot.quantity) >
                      60,
                  active3:
                    _vm.perNum(_vm.getMinPassQuantity(lot.bom_info), lot.quantity) <=
                      60 &&
                    _vm.perNum(_vm.getMinPassQuantity(lot.bom_info), lot.quantity) >
                      40,
                  active4:
                    _vm.perNum(_vm.getMinPassQuantity(lot.bom_info), lot.quantity) <=
                      40 &&
                    _vm.perNum(_vm.getMinPassQuantity(lot.bom_info), lot.quantity) >=
                      0,
                }},[_vm._v(" "+_vm._s(((_vm.perNum(_vm.getMinPassQuantity(lot.bom_info), lot.quantity)) + "%"))+" ")])])}),0)])])]),_c('div',{staticClass:"progress_contents"},[_c('div',{staticClass:"swiper-container"},[_c('div',{staticClass:"swiper-wrapper"},_vm._l((_vm.target_lot2 != null
              ? _vm.target_lot2.bom_info.bom_process
              : []),function(lot_process){return _c('process-status-card',{key:lot_process.id + _vm.redraw,attrs:{"process_id":_vm.process.process_id,"process_name":_vm.findInfoFromId(_vm.process, lot_process.process_id).name,"sub":lot_process.index + 1,"id":("chartData" + (lot_process.id)),"lot_quantity":_vm.target_lot2.quantity,"wait_quantity":_vm.getWaitQuantity(lot_process),"production_info":lot_process.production_info,"lot_id":_vm.target_lot2.id,"bom_process_id":lot_process.id,"lot_type_id":3}})}),1)]),_vm._m(1)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',{attrs:{"rowspan":"2"}},[_vm._v("지시일자")]),_c('th',{attrs:{"rowspan":"2"}},[_vm._v("납품 요구일")]),_c('th',{attrs:{"colspan":"3"}},[_vm._v("제품 공정정보")]),_c('th',{attrs:{"colspan":"2"}},[_vm._v("공정 진행현황")]),_c('th',{attrs:{"rowspan":"2"}},[_vm._v(" 생산완료율 ")])]),_c('tr',[_c('th',[_vm._v("제품명")]),_c('th',[_vm._v("LOT NO")]),_c('th',[_vm._v("공정수")]),_c('th',[_vm._v("지시수량")]),_c('th',[_vm._v("생산수량")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"slide_btn_wrap"},[_c('div',[_c('button',{staticClass:"button-prev",attrs:{"type":"button"}},[_c('i',{staticClass:"fa fa-angle-left"})]),_c('button',{staticClass:"button-next",attrs:{"type":"button"}},[_c('i',{staticClass:"fa fa-angle-right"})])])])}]

export { render, staticRenderFns }