<template>
  <div class="swiper-slide">
    <div class="item">
      <div class="chart">
        <div class="title">
          <h6>{{ process_name }}</h6>
          <span>{{ `SUB ${sub}` }}</span>
        </div>
        <div class="chartData">
          <!-- <div class="chart_info">
            <span>SEQ1</span>
            <span>SEQ2</span>
            <span>SEQ3</span>
            <span>SEQ4</span>
          </div> -->
          <div :id="this.id"></div>
          <ProductionBarChart
            :key="key"
            :data="data"
            :options="options"
          ></ProductionBarChart>
        </div>
      </div>
      <div class="tbl_data">
        <div class="title">
          <span>세부공정</span>
        </div>
        <div class="tbl">
          <table>
            <thead>
              <tr>
                <th>SEQ</th>
                <th>일자</th>
                <th>완료</th>
                <th>불량</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(seq, index) in sorted_production_seq"
                :key="seq.id"
                @click="selectRow(index)"
                :class="selectedIndex == index ? 'active' : ''"
              >
                <td>{{ index + 1 }}</td>
                <td>{{ seq.create_time | formatDateNoHours }}</td>
                <td>{{ $makeComma(seq.pass_quantity) }}</td>
                <td>{{ $makeComma(seq.fail_quantity) }}</td>
              </tr>
              <tr
                v-if="
                  remain_quantity > 0 &&
                    lot_type_id !=
                      lot_types.find(x => x.detail == 'done process').id
                "
                @click="selectRow(-1)"
              >
                <td>
                  new
                </td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!-- <div class="process_data active">
        <table>
          <tbody v-if="selectedIndex == -1">
            <tr>
              <th>일자</th>
              <td></td>
            </tr>
            <tr>
              <th>진행</th>
              <td></td>
            </tr>
            <tr>
              <th>통과</th>
              <td></td>
            </tr>
            <tr>
              <th>불량</th>
              <td></td>
            </tr>
          </tbody>

          <tbody v-else>
            <tr>
              <th>일자</th>
              <td>
                <input
                  type="text"
                  class="datepicker"
                  placeholder="YYYY-MM-DD"
                  :value="managementData.input_date"
                  readonly
                />
              </td>
            </tr>
            <tr>
              <th>진행</th>
              <td>{{ managementData.quantity }}</td>
            </tr>
            <tr>
              <th>통과</th>
              <td>
                <input
                  type="number"
                  v-model="managementData.pass_quantity"
                  :max="managementData.quantity"
                  :min="0"
                  :readonly="selectedIndex != -1"
                />
              </td>
            </tr>
            <tr>
              <th>불량</th>
              <td>
                <input
                  type="number"
                  v-model="managementData.fail_quantity"
                  :max="managementData.quantity - managementData.pass_quantity"
                  :min="0"
                  :readonly="selectedIndex != -1"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div> -->
    </div>
  </div>
</template>

<script>
// import tuiChart from 'tui-chart';
import { yyyymmdd } from '@/utils/func';
import { formatDateNoHours } from '@/utils/filters';
import { mapGetters } from 'vuex';
import ProductionBarChart from '@/layouts/components/charts/ProductionBarChart';

export default {
  components: {
    ProductionBarChart,
  },
  data() {
    return {
      selectedIndex: -1,
      chart: null,
      managementData: {},
      data: null,
      options: null,
      key: 0,
      screenWidth: this.$screen.width,
    };
  },
  props: {
    process_id: {
      required: true,
    },
    process_name: {
      type: String,
    },
    sub: {
      type: Number,
    },
    id: {
      type: String,
      required: true,
    },
    lot_quantity: {
      type: Number,
      required: true,
    },
    wait_quantity: {
      type: Number,
      required: true,
    },
    production_info: {
      required: true,
    },
    lot_id: {
      required: true,
    },
    bom_process_id: {
      required: true,
    },
    lot_type_id: {
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      lot_types: 'getLotType',
    }),
    remain_quantity() {
      if (this.production_info != null) {
        return (
          this.wait_quantity -
          this.production_info.pass_quantity -
          this.production_info.fail_quantity
        );
      } else {
        return this.wait_quantity;
      }
    },
    sorted_production_seq() {
      if (this.production_info != null) {
        return this.lodash.clonedeep(
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.production_info.seq_list.sort((a, b) => a.id - b.id),
        );
      } else {
        return [];
      }
    },
  },

  mounted() {
    this.drawChart();
  },
  methods: {
    submit() {
      let payload = this.lodash.clonedeep(this.production_info);
      if (payload == null) {
        payload = {
          id: -1,
          lot_id: this.lot_id,
          bom_process_id: this.bom_process_id,
          lot_process_type_id: 1,
          pass_quantity: this.managementData.pass_quantity,
          fail_quantity: this.managementData.fail_quantity,
          input_date: this.managementData.input_date,
        };
      } else {
        payload.pass_quantity =
          Number(payload.pass_quantity) +
          Number(this.managementData.pass_quantity);
        payload.fail_quantity =
          Number(payload.fail_quantity) +
          Number(this.managementData.fail_quantity);
        payload.input_date = this.managementData.input_date;
      }
      payload.seq = this.lodash.clonedeep(this.managementData);
      if (this.selectedIndex == -1) {
        console.log('new');
        this.$emit('oninsert', payload);
        this.selectRow(-1);
      } else {
        console.log('delete');
        this.$emit('ondelete', payload);
        this.selectRow(-1);
      }
    },
    selectRow(index) {
      this.selectedIndex = index;
      if (index != -1) {
        const seq = this.production_info.seq_list[this.selectedIndex];
        this.managementData = {
          id: seq.id,
          input_date: formatDateNoHours(seq.create_time),
          quantity: seq.quantity,
          pass_quantity: seq.pass_quantity,
          fail_quantity: seq.fail_quantity,
        };
      } else {
        var date = new Date();
        this.managementData = {
          id: -1,
          input_date: yyyymmdd(date),
          quantity: this.remain_quantity,
          pass_quantity: this.remain_quantity,
          fail_quantity: 0,
        };
      }
    },
    drawChart() {
      let pass_quantity = 0;
      let fail_quantity = 0;
      if (this.production_info != null) {
        pass_quantity = this.production_info.pass_quantity;
        fail_quantity = this.production_info.fail_quantity;
      }
      if (pass_quantity == undefined) pass_quantity = 0;
      if (fail_quantity == undefined) fail_quantity = 0;
      let list = [];
      let seq_list = null;

      if (this.production_info != null) {
        seq_list = this.lodash.clonedeep(
          this.production_info.seq_list.sort((a, b) => a.id - b.id),
        );
        let pass_ratio = 0;
        let fail_ratio = 0;
        if (seq_list.filter(x => x.pass_quantity > 0).length > 0) {
          pass_ratio = (
            0.8 / seq_list.filter(x => x.pass_quantity > 0).length
          ).toFixed(12);
        }
        if (seq_list.filter(x => x.fail_quantity > 0).length > 0) {
          fail_ratio = (
            0.8 / seq_list.filter(x => x.fail_quantity > 0).length
          ).toFixed(12);
        }

        let sumPass = 0;
        let sumFail = 0;
        console.log('pass_ratio', pass_ratio);
        console.log('fail_ratio', fail_ratio);
        seq_list.forEach((el, index) => {
          console.log(`index = ${index} & pass_quantity = ${sumPass}`);
          if (el.pass_quantity > 0) {
            list.push({
              // label: `SEQ${index + 1} 통과`,
              backgroundColor: `rgbA(0,74,137,${1 - sumPass})`,
              data: [el.pass_quantity, 0, 0],
              tooltip: false,
              xAxisID: 'bar-x-axis1',
            });
            sumPass = Number(sumPass) + Number(pass_ratio);
            // label_pass_quantity =
            //   Number(label_pass_quantity) + Number(el.pass_quantity);
          }
        });
        seq_list.forEach(el => {
          if (el.fail_quantity > 0) {
            list.push({
              // label: `SEQ${index + 1} 불량`,
              backgroundColor: `rgbA(249,135,135,${1 - sumFail})`,
              data: [0, el.fail_quantity, 0],
              tooltip: false,
              xAxisID: 'bar-x-axis1',
            });
            sumFail = Number(sumFail) + Number(fail_ratio);
          }
        });
      }
      list.push({
        // label: '미투입량',
        backgroundColor: '#dddddd',
        data: [0, 0, this.wait_quantity - pass_quantity - fail_quantity],
        xAxisID: 'bar-x-axis1',
        tooltip: false,
        categoryPercentage: 0.8,
      });

      let data = {
        labels: ['통과', '불량', '미투입량'],
        datasets: list,
      };

      let fontSize = 12;
      if (this.screenWidth < 1280) {
        fontSize = 10;
      }

      let maxLen = 0;
      if (this.lot_quantity >= 1) {
        let decimal = 0;
        decimal = String(Math.round(this.lot_quantity)).length - 1;
        let cnt = '1';
        for (let i = 0; i < decimal; i++) {
          cnt += '0';
        }
        this.$makeNumber(cnt);
        maxLen = Math.ceil(this.lot_quantity / cnt) * cnt;
      } else {
        maxLen = this.lot_quantity;
      }

      let options = {
        legend: {
          display: false,
        },
        scales: {
          xAxes: [
            {
              stacked: true,
              id: 'bar-x-axis1',
              ticks: {
                fontSize,
              },
            },
          ],
          dataset: {
            barPercentage: 0.9,
          },
          yAxes: [
            {
              stacked: true,
              ticks: {
                // beginAtZero: true,
                max: maxLen,
                fontSize,
                callback: value => {
                  return this.$makeComma(value);
                },
              },
            },
          ],
        },
        plugins: {
          datalabels: {
            color: '#ffffff',
          },
        },
        showTooltips: true,
        tooltips: {
          mode: 'index',
          intersect: false,
          custom: function(tooltip) {
            if (!tooltip) return;
            tooltip.displayColors = false;
          },
          callbacks: {
            label: tooltipItems => {
              if (tooltipItems.datasetIndex === 0) {
                var multistringText = [];
                switch (tooltipItems.index) {
                  case 0:
                    if (seq_list != null) {
                      seq_list.forEach((el, index) => {
                        el.pass_quantity = this.$makeNumber(el.pass_quantity);
                        console.log('el ', el);

                        if (el.pass_quantity > 0) {
                          multistringText.push(
                            `[SEQ${index + 1} 통과] : ${this.$makeComma(
                              el.pass_quantity,
                            )}`,
                          );
                        }
                      });
                    }
                    break;
                  case 1:
                    if (seq_list != null) {
                      seq_list.forEach((el, index) => {
                        el.fail_quantity = this.$makeNumber(el.fail_quantity);

                        if (el.fail_quantity > 0) {
                          multistringText.push(
                            `[SEQ${index + 1} 불량] : ${this.$makeComma(
                              el.fail_quantity,
                            )}`,
                          );
                        }
                      });
                    }
                    break;
                  case 2:
                    multistringText.push(
                      `${this.$makeComma(
                        this.$decimalSub(
                          this.wait_quantity,
                          this.$decimalSub(pass_quantity, fail_quantity),
                        ),
                      )}`,
                    );
                    break;
                }
                return multistringText;
              }
            },
          },
        },
      };
      this.data = data;
      this.options = options;
      this.key += 1000;
      console.log(this.data);
      console.log(this.options);
      // let container = document.getElementById(this.id);
      // this.renderChart(container, data, options);
      // this.drawChart = new Chart(container, {
      //   type: 'bar',
      //   data: data,
      //   options: options,
      // });
      // } else {
      //   var container = document.getElementById(this.id);
      //   var data = {
      //     categories: ['통과', '불량', '미투입량'],
      //     series: [
      //       {
      //         name: '통과',
      //         data: [pass_quantity, 0, 0],
      //       },
      //       {
      //         name: '불량',
      //         data: [0, fail_quantity, 0],
      //       },
      //       {
      //         name: '미투입량',
      //         data: [0, 0, this.wait_quantity - pass_quantity - fail_quantity],
      //       },
      //     ],
      //   };
      // var options = {
      //   chart: {
      //     width: 225,
      //     height: 200,
      //     format: '500',
      //   },
      //   series: {
      //     stack: 500,
      //     barWidth: 18,
      //   },
      //   tooltip: {
      //     grouped: false,
      //   },
      //   legend: {
      //     visible: false,
      //   },
      //   chartExportMenu: {
      //     visible: false,
      //   },
      //   yAxis: {
      //     max: this.lot_quantity,
      //   },
      //   };
      //   var theme = {
      //     series: {
      //       column: {
      //         colors: ['#004a89', '#f98787', '#dddddd'],
      //       },
      //     },
      //   };

      //   // For apply theme
      //   tuiChart.registerTheme(`myTheme${this.id}`, theme);
      //   options.theme = `myTheme${this.id}`;
      //   this.chart = tuiChart.columnChart(container, data, options);
      // };
    },
  },
  watch: {
    lot_quantity() {
      this.chart.destroy();
      this.drawChart();
    },
  },
  created() {
    this.selectedIndex = -1;
    var date = new Date();
    this.managementData = {
      input_date: yyyymmdd(date),
      quantity: this.remain_quantity,
      pass_quantity: this.remain_quantity,
      fail_quantity: 0,
    };
  },
};
</script>

<style lang="scss" scoped></style>
